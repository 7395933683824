import EncodeUrl from '@/utils/encode';
import { nameRouterSearch } from '@/utils/constants'

const convertRouterStory = (nameStory, idStory) => {
  return `/doc-truyen/${EncodeUrl(nameStory)}/${idStory}`;
};
const convertRouterChapter = (story, chapter) => {
  return `/${EncodeUrl(story?.name)}/${EncodeUrl(story?.id)}/${EncodeUrl(chapter?.storySections?.number)}/chuong-${chapter?.number}`;
};
const convertRouterConverter = (idConverter) => {
  return `/tim-kiem-tong-hop?${nameRouterSearch.converter}=${idConverter}`;
};
const convertRouterCategory = (nameCate, idCate) => {
  return `/the-loai/${nameCate}/${idCate}`;
  // return `/the-loai/${EncodeUrl(nameCate)}/${idCate}`;
};
const convertRouterAuthor = (idAuthor) => {
  return `/tim-kiem-tong-hop?${nameRouterSearch.author}=${idAuthor}`;
};

export { convertRouterCategory, convertRouterStory, convertRouterChapter, convertRouterConverter, convertRouterAuthor };
