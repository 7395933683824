/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Spin, Popconfirm, Form, Modal, notification } from 'antd';
import { /* defineMessages, */ injectIntl } from 'react-intl';
import regexHelper from '@/utils/regexHelper';

// import { userid } from '@/utils/helpers';

const FormItem = Form.Item;

@connect(({ login: { info } }) => ({
  login: info,
}))
@Form.create()
@injectIntl
class Index extends PureComponent {

  state = {
    spinning: false,
    visibleModal: false,
    key: Math.random(),
    number: 1,
  };

  componentWillReceiveProps(nextProps) {
    const { visible } = this.props;
    if (nextProps.visible !== visible) {
      this.changeModal('show');
    }
  }

  // Lưu giá trị thay đổi
  handleSubmit = () => {
    const { dispatch, form } = this.props;
    form.validateFields(['emailF'], (err, values) => {
      if (!err) {
        this.setState({
          spinning: true,
        });
        const addItem = {
          email: values.emailF
        };
        dispatch({
          type: 'login/requestForgetPass',
          payload: addItem,
          callback: result => {
            this.setState({
              spinning: false,
            });
            if (result && result.success === true) {
              notification.success({ message: 'Vui lòng check email của bạn để lấy lại mật khẩu', placement: 'bottomRight', style: { background: '#f6ffed' } });
              this.changeModal('close');
            } else if (result && result.success === false) {
              notification.error({ message: result && result.error && result.error.message, placement: 'bottomRight', style: { background: '#fff1f0' } });
            }
          },
        })
      }
    })
  };

  // Xử lý sự kiện click button Hủy
  handleReset = () => {
    // console.log("reset")
    const { form } = this.props
    const { number } = this.state;
    form.resetFields();
    this.setState({
      // helpPhone: "",
      // statusPhone: "",
      number: number + 1,
    });
  };

  changeModal = value => {
    const { visibleModal, key } = this.state;
    if (value === 'show') {
      if (visibleModal) {
        this.handleReset();
      }
      this.setState({
        visibleModal: !visibleModal,
        key: key + 1,
      });
    } else if (value === 'close') {
      this.handleReset()
      this.setState({
        visibleModal: false,
        spinning: false,
        key: Math.random(),
        number: 1,
      });
    }
  };

  render() {
    const {
      submitting,
      intl, form: { getFieldDecorator }
    } = this.props;
    const { spinning, visibleModal, } = this.state;
    // const data = suppliers.info;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      colon: false,
      labelAlign: 'left',
      style: { marginBottom: 0 },
    };

    if (!visibleModal) { return null }
    return (
      <Modal
        title='Lấy lại mật khẩu tài khoản'
        visible={visibleModal}
        bodyStyle={{ padding: '15px 50px' }}
        onCancel={() => this.changeModal('close')}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              id='btnLogin'
              style={{ marginLeft: 8, marginRight: 15, background: '#3e3d43', color: '#fff', borderRadius: '5px', border: 'unset' }}
              loading={submitting}
              onClick={this.handleSubmit}
            >
              Gửi Email
            </Button>

          </div>}
      >
        <Spin spinning={spinning}>
          <Form
            hideRequiredMark
            style={{ marginTop: 8 }}
            autoComplete="off"
          >
            <FormItem
              {...formItemLayout}
            >
              {getFieldDecorator('emailF', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
                  },
                  {
                    type: 'email',
                    message: intl.formatMessage({ id: 'app.common.crud.validate.email' }),
                  },
                ],
              })(
                <Input placeholder="Email" size="large" style={{ borderRadius: 5 }} />
              )}
            </FormItem>
          </Form>
        </Spin>
      </Modal>

    );
  }
}

export default Index;
