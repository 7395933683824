/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable global-require */
import React from 'react';
import cookie from 'cookie';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { connect } from 'react-redux';
import { getComponentDisplayName } from '../utils/helpers';
import moment from 'moment';

const { publicRuntimeConfig } = getConfig();
const Control = dynamic(() => import('./ControlLayout'), {
  ssr: true,
  loading: () => null,
});
// @connect(({ login }) => ({ currentUser: login?.currentUser }))
export default ComposedComponent => {
  class WebLayout extends React.Component {
    static displayName = `WebLayout(${getComponentDisplayName(ComposedComponent)})`;

    static async getInitialProps(context) {
      const { req, asPath, pathname, query, store, dispatch } = context;
      let typeLayout = 'home';
      const checkChitietchuong =
        asPath?.indexOf('/doc-truyen/') !== 0 &&
        asPath?.indexOf('/login') !== 0 &&
        asPath?.indexOf('/the-loai/') !== 0 &&
        asPath?.indexOf('/tap-van/') !== 0 &&
        asPath?.indexOf('/bang-xep-hang') !== 0 &&
        (asPath !== '/tim-kiem-tong-hop' || !asPath?.includes('/tim-kiem-tong-hop?')) &&
        asPath !== '/danh-gia' &&
        asPath !== '/' &&
        asPath?.indexOf('/?') !== 0;

      if (checkChitietchuong) {
        typeLayout = 'detail';
      }
      if (asPath.indexOf('/trang-ca-nhan/') === 0) {
        typeLayout = 'user';
      }
      if (
        asPath?.indexOf('/doc-truyen/') === 0 ||
        asPath?.indexOf('/login') === 0 ||
        asPath?.indexOf('/the-loai/') === 0 ||
        asPath?.indexOf('/tap-van/') === 0 ||
        asPath?.indexOf('/bang-xep-hang') === 0 ||
        (asPath === '/tim-kiem-tong-hop' || asPath?.includes('/tim-kiem-tong-hop?')) ||
        asPath === '/danh-gia'
      ) {
        typeLayout = 'category';
      }
      // console.log(asPath, typeLayout);
      let cookies;
      // let protocol = 'https:';
      let host = '';
      if (req) {
        cookies = req.cookies;
        host =
          req.headers.host === 'localhost:8884' ? publicRuntimeConfig.SITE_NAME : req.headers.host;
      } else {
        const documentCookie = document.cookie;
        cookies = cookie.parse(documentCookie);
        host =
          window.location.host === 'localhost:8884'
            ? publicRuntimeConfig.SITE_NAME
            : window.location.host;
      }
      if (host?.indexOf('www') === 0) {
        host = host.slice(4);
      }
      function fetchSite() {
        return new Promise(resolve => {
          const querySite = {
            filter: {
              status: '1',
              url: `${host}`,
              languagesId: '1',
            },
            attributes: `id,url,name,seoKeywords,seoDescriptions,logo,icon`,
          };
          dispatch({
            type: 'webs/fetchSiteUrl',
            payload: querySite,
            callback: res => {
              if (res?.success) {
                resolve({ data: res?.result?.list?.length > 0 && res?.result?.list[0] });
              } else {
                resolve({ error: res?.warring?.message });
              }
              // console.log('res: ', res);
            },
          });
        });
      }
      function fetchStoryCategories() {
        return new Promise(resolve => {
          const param = {
            filter: JSON.stringify({
              status: '1',
              parentId: '0',
            }),
            range: JSON.stringify([0, 999]),
            sort: JSON.stringify(['id', 'ASC']),
            attributes: `id,name,images,status,dateCreated,urlSlugs,totalStories`,
          };
          dispatch({
            type: 'webs/fetchStoryCategories',
            payload: param,
            callback: res => {

              if (res?.success) {
                resolve({ data: res?.result?.list || [] });
              } else {
                resolve({ error: res?.warring?.message });
              }
              // console.log('res: ', res);
            },
          });
        });
      }
      let dataSite = cookies.dataSite && JSON.parse(cookies.dataSite) || {}
      if ((moment() - (cookies.setSiteTime && moment(cookies.setSiteTime, 'HH:mm:ss DD/MM/YYYY') || moment().subtract(1, 'days')) > 7200000) || !dataSite?.id) {
        dataSite = await fetchSite();
        dataSite = await dataSite?.data || {}
        cookie.serialize('dataSite', JSON.stringify(dataSite));
        cookie.serialize('setSiteTime', moment().format('HH:mm:ss DD/MM/YYYY'));
      }
      const SITEID_WEB = dataSite?.id || publicRuntimeConfig.SITEID_WEB;
      if (SITEID_WEB && typeLayout === 'home') {
        const queryMenus = await {
          filter: {
            status: '1',
            menuPositionsId: `${publicRuntimeConfig.MENU__POSITIONID_TOP},${publicRuntimeConfig.MENU__POSITIONID_BOTTOM
              },${publicRuntimeConfig.MENU__POSITIONID_LEFT}`,
            languagesId: '1',
            sitesId: SITEID_WEB,
          },
          sort: ['orderBy', 'ASC'],
        };
        await dispatch({
          type: 'webs/fetchMenus',
          payload: queryMenus,
        });
      }
      if (cookies.token) {
        await dispatch({
          type: 'login/fetchCurrent',
          payload: {
            cookies
          }
        });
      }
      const {
        webs: { data },
        login: { currentUser }
      } = await store.getState();
      const menus = data?.list || [];
      // console.log('menu',menus)
      const menuHeader =
        (menus &&
          menus.length > 0 &&
          menus.filter(
            item =>
              Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_TOP)
          )) ||
        [];
      const menuHeaderFilter =
        (menuHeader && menuHeader.length > 0 && menuHeader.filter(item => !item.parent)) || [];
      const menuFooter =
        (menus &&
          menus.length > 0 &&
          menus.filter(
            item =>
              Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_BOTTOM)
          )) ||
        [];
      const menuFooterFilter =
        (menuFooter && menuFooter.length > 0 && menuFooter.filter(item => !item.parent)) || [];

      const menuLeft =
        (menus &&
          menus.length > 0 &&
          menus.filter(
            item =>
              Number(item.menuPositionsId) === Number(publicRuntimeConfig.MENU__POSITIONID_LEFT)
          )) ||
        [];
      const menuLeftFilter =
        (menuLeft && menuLeft.length > 0 && menuLeft.filter(item => !item.parent)) || [];
      const newSiteProfile =
        dataSite &&
        dataSite.siteProfiles &&
        dataSite.siteProfiles.length > 0 &&
        dataSite.siteProfiles.find(x => x.languagesId === '1');
      const newDataSite = {
        ...dataSite,
        siteProfiles: newSiteProfile || {},
      };
      const resultUpdateStories = await fetchStoryCategories();
      const menuParentCategories = resultUpdateStories?.data || []
      const filterCategories = menuParentCategories.filter(item => item.status === 1)
      return {
        ...(ComposedComponent.getInitialProps
          ? await ComposedComponent.getInitialProps(context)
          : {}),
        cookies,
        asPath,
        pathname,
        dataSite: newDataSite,
        query,
        menuLeft: menuLeftFilter,
        menuHeader: menuHeaderFilter,
        menuFooter: menuFooterFilter,
        menuParentCategories: filterCategories,
        currentUser,
        typeLayout
      };
    }

    componentDidMount() {
      const { dataSite } = this.props
      if ((moment() - (Cookies.get('setSiteTime') && moment(Cookies.get('setSiteTime'), 'HH:mm:ss DD/MM/YYYY') || moment().subtract(1, 'days')) > 7200000)) {
        Cookies.set('dataSite', JSON.stringify(dataSite));
        Cookies.set('setSiteTime', moment().format('HH:mm:ss DD/MM/YYYY'));
      }
      // document.addEventListener('contextmenu', (e) => {
      //   e.preventDefault();
      // });
      document.addEventListener('cut copy', (e) => {
        e.preventDefault();
      });
      document.addEventListener('keydown', (e) => {
        if (e.keyCode === 123) {
          e.returnValue = false;
        }
      });
    };

    render() {
      const { asPath, currentUser, dataSite, typeLayout, menuParentCategories } = this.props;
      console.log('menuParentCategories ', menuParentCategories)
      return (
        <React.Fragment>
          <Control {...this.props} typeLayout={typeLayout}>
            <ComposedComponent {...this.props} />
          </Control>
        </React.Fragment>
      );
    }
  }

  return WebLayout;
};
