/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Spin, Popconfirm, Form, Modal, notification, Row, Col } from 'antd';
import { /* defineMessages, */ injectIntl } from 'react-intl';
import moment from 'moment';
import regexHelper from '@/utils/regexHelper';

// import { userid } from '@/utils/helpers';

const FormItem = Form.Item;
const { isfullName, isMobile, isTextNumberOrEmail } = regexHelper;

@connect(({ login: { info } }) => ({
  login: info,
}))
@Form.create()
@injectIntl
class Index extends PureComponent {

  state = {
    spinning: false,
    visibleModal: false,
    key: Math.random(),
    number: 1,
    help: null,
    status: '',
    confirmDirty: false,
  };

  componentWillReceiveProps(nextProps) {
    const { visible } = this.props;
    if (nextProps.visible !== visible) {
      this.changeModal('show');
    }
  }

  // Lưu giá trị thay đổi
  handleSubmit = () => {
    const { dispatch, intl, form } = this.props;
    form.validateFields(['usernameR', 'fullnameR', 'mobileR', 'emailR', 'passwordR', 'password2R'], (err, values) => {
      // console.log(values);
      if (!values.passwordR) {
        this.setState({
          help: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
          status: 'error',
        });
      }
      if (!err && values.passwordR) {
        this.setState({
          spinning: true,
        });
        const checkPhone = values.mobileR?.indexOf('+') !== -1;
        const mobiles = checkPhone ? values.mobileR?.slice(1, values.mobileR.length) : (values?.mobile || '');
        const addItem = {
          email: values.emailR,
          mobile: mobiles?.replace(/\s/g, ''),
          fullname: values.fullnameR.trim(),
          username: values.usernameR.trim(),
          password: values.passwordR,
          userGroupsId: 11,
          status: 1,
          image: null,
          dateUpdated: moment(),
        };
        dispatch({
          type: 'login/register',
          payload: addItem,
          callback: result => {
            this.setState({
              spinning: false,
            });
            if (result && result.success === true) {
              notification.success({
                message: 'Đăng ký thành công',
                placement: 'bottomRight',
                style: { background: '#f6ffed' },
              });
              this.changeModal('close');
            } else if (result && result.success === false) {
              notification.error({
                message: result && result.error && result.error.message,
                placement: 'bottomRight',
                style: { background: '#fff1f0' },
              });
            }
          },
        });
      }
    })
  };

  // Validate PAss mới phải giống Pass nhập lại
  compareToFirstPassword = (rule, value, callback) => {
    const { intl, form } = this.props;
    if (value && value !== form.getFieldValue('passwordR')) {
      callback(intl.formatMessage({ id: 'app.user.validate.password2' }));
    } else {
      callback();
    }
  };

  // Xử lý sự kiện click button Hủy
  handleReset = () => {
    const { form } = this.props;
    // console.log("reset")
    const { number } = this.state;
    form.resetFields();
    this.setState({
      help: null,
      status: '',
      number: number + 1,
    });
  };

  // Ngăn event Copy
  paste = e => {
    e.preventDefault();
  };

  // Kiểm tra caplock có bật hay không
  checkCapsLockOld = e => {
    const { intl } = this.props;
    const capsLockOn = e.getModifierState('CapsLock');
    if (capsLockOn === true) {
      this.setState({
        help: intl.formatMessage({ id: 'app.login.validate.capsLock' }),
        status: 'warning',
      });
    } else {
      this.setState({
        help: null,
        status: 'success',
      });
    }
  };

  // Validate nhập mật khẩu
  checkRequiredNew = e => {
    const { intl } = this.props;
    const { value } = e.target;
    // hông được trống
    if (!value) {
      this.setState({
        help: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
        status: 'error',
      });
    }
    // Lớn hơn 6 ký tự
    else if (value.length < 6) {
      this.setState({
        help: intl.formatMessage({ id: 'app.user.validate.passwordmin' }),
        status: 'error',
      });
    }
  };

  changeModal = value => {
    const { visibleModal, key } = this.state;
    if (value === 'show') {
      if (visibleModal) {
        this.handleReset();
      }
      this.setState({
        visibleModal: !visibleModal,
        key: key + 1,
      });
    } else if (value === 'close') {
      this.handleReset();
      this.setState({
        visibleModal: false,
        spinning: false,
        key: Math.random(),
        number: 1,
      });
    }
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    const { confirmDirty } = this.state;
    this.setState({ confirmDirty: confirmDirty || !!value });
  };

  render() {
    const { submitting, intl,
      form: { getFieldDecorator } } = this.props;
    const { spinning, visibleModal, help, status } = this.state;
    // const data = suppliers.info;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
      },
      colon: false,
      labelAlign: 'left',
      style: { marginBottom: 0 },
    };

    if (!visibleModal) {
      return null;
    }
    return (
      <Modal
        title={
          <>
            <div
              style={{
                fontSize: '25px',
                fontWeight: 'bold',
              }}
            >
              Đăng ký
            </div>
            <div
              style={{
                fontSize: '13px',
                paddingTop: '10px',
              }}
            >
              Nhanh chóng và dễ dàng.
            </div>
          </>
        }
        visible={visibleModal}
        bodyStyle={{ padding: '15px 40px' }}
        style={{ top: 50 }}
        onCancel={() => this.changeModal('close')}
        footer={null}
      >
        <Spin spinning={spinning}>
          <Form
            style={{ marginTop: 8 }}
            id='dang-ky-form'
          >
            <Row gutter={[10, 10]}>
              <Col sm={12} xs={24}>
                <FormItem
                  {...formItemLayout}
                >
                  {getFieldDecorator('fullnameR', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
                      },
                      {
                        pattern: isfullName,
                        message: intl.formatMessage({ id: 'app.user.validate.fullNamepattern' }),
                      },
                    ],
                  })(
                    <Input
                      placeholder="Họ và tên"
                      id="fullname"
                      size="large"
                      style={{ borderRadius: 5 }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col sm={12} xs={24}>
                <FormItem
                  {...formItemLayout}
                >
                  {getFieldDecorator('usernameR', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
                      },
                      {
                        pattern: isTextNumberOrEmail,
                        message: `${intl.formatMessage({
                          id: 'app.login.validate.userName',
                        })} hoặc nhập email`,
                      },
                    ],
                  })(
                    <Input
                      placeholder="Tên đăng nhập"
                      id="username"
                      size="large"
                      style={{ borderRadius: 5 }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col sm={12} xs={24}>
                <FormItem
                  {...formItemLayout}
                  validateStatus={status}
                  help={help}
                >
                  {getFieldDecorator('passwordR', {
                  })(
                    <Input.Password
                      placeholder="Mật khẩu"
                      name="pwd"
                      size="large"
                      style={{ borderRadius: 5 }}
                      autoComplete="new-password"
                      onKeyPress={e => this.checkCapsLockOld(e)}
                      onChange={e => this.checkRequiredNew(e)}
                    />
                  )}
                </FormItem>
              </Col>
              <Col sm={12} xs={24}>
                <FormItem
                  {...formItemLayout}
                >
                  {getFieldDecorator('password2R', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input.Password
                      placeholder="Nhập lại mật khẩu"
                      onPaste={e => this.paste(e)}
                      size="large"
                      style={{ borderRadius: 5 }}
                      onBlur={this.handleConfirmBlur}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col sm={12} xs={24}>
                <FormItem
                  {...formItemLayout}
                >
                  {getFieldDecorator('emailR', {
                    rules: [
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
                      },
                      {
                        type: 'email',
                        message: intl.formatMessage({ id: 'app.common.crud.validate.email' }),
                      },
                    ],
                  })(
                    <Input placeholder="Email" size="large" style={{ borderRadius: 5 }} />
                  )}
                </FormItem>
              </Col>
              <Col sm={12} xs={24}>
                <FormItem
                  {...formItemLayout}
                >
                  {getFieldDecorator('mobileR', {
                    rules: [
                      {
                        pattern: isMobile,
                        message: intl.formatMessage({ id: 'app.common.crud.validate.phone' }),
                      },
                    ],
                  })(
                    <Input placeholder="Số điện thoại" size="large" style={{ borderRadius: 5 }} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn" />
                <button type="submit" onClick={this.handleSubmit} className="login100-form-btn">
                  Đăng ký
                </button>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

export default Index;
