// Tên các trường tìm kiếm ở router trang tìm kiếm tổng hợp
const nameRouterSearch = {
  complete: 'cpt', // hoàn thành ?
  update: 'upd', // update mới nhất
  parentCate: 'pCate', // danh mục cha
  childCate: 'cateId', // danh mục con =0 -> Khác
  create: 'crt', // truyen moi
  typeStory: 'type', // loai truyen
  rank: 'rank', // Xep hang theo
  chuong: 'sc', // So chuong
  tag: 'tag', // tag truyen
  sort: 'srt', // sap xep theo gi: update, new, so chuong
  time: 'time', // Thoi gian
  page: 'page', // phân trang
  nameSearch: 'nameSearch', // Tim kiem theo ten truyen hoac tac gia
  author: 'author', // tac gia
  converter: 'converter', // nguoi dang
};

// Chuyên mục tin tức
const categoriesNewsId = '31';
// QUang cao banner trang chu
const adsPositionsId = '26';
// QUang cao chinh giua
const adsPositionsCenterId = '27';

const adsPositionsCenterMobileId = '28';

// Tình trạng
const completeArr = [
  {
    id: '0',
    name: 'Đang ra',
  },
  {
    id: '1',
    name: 'Hoàn thành',
  },
];
// Xếp hạng
const rankArr = [
  {
    id: 'view',
    name: 'Xem nhiều',
  },
  {
    id: 'nominate',
    name: 'Đề cử',
  },
  {
    id: 'like',
    name: 'Yêu thích',
  },
  {
    id: 'follow',
    name: 'Theo dõi',
  },
  {
    id: 'comments',
    name: 'Bình luận',
  },
];
// Sắp xếp
const sortArr = [
  // {
  //     id:'dateUpdated',
  //     name: 'Mới cập nhật'
  // },
  {
    id: 'dateCreated',
    name: 'Truyện mới',
  },
  {
    id: 'countChapter',
    name: 'Số chương',
  },
];
// Số chương
const numberChapterArr = [
  {
    id: '299',
    name: '< 300',
  },
  {
    id: '1000',
    name: '300 - 1000',
  },
  {
    id: '2000',
    name: '1001 - 2000',
  },
  {
    id: '2001',
    name: '> 2000',
  },
];
// Thời gian
const timeArr = [
  {
    id: '1',
    name: 'Trong ngày',
  },
  {
    id: '2',
    name: 'Trong tuần',
  },
  {
    id: '3',
    name: 'Trong tháng',
  },
  {
    id: '4',
    name: 'Trong năm',
  },
];

const convertDataFilterStories = (query, pageSize) => {
  const filter = {
    status: 1,
  };
  let sort = ['dateUpdated', 'desc'];
  let range = [0, pageSize - 1]
  if (query[nameRouterSearch.nameSearch]) {
    filter.searchString = query[nameRouterSearch.nameSearch];
  }
  if (query[nameRouterSearch.converter]) {
    filter.usersCreatorId = query[nameRouterSearch.converter];
  }
  if (query[nameRouterSearch.author]) {
    filter.storyAuthorsId = query[nameRouterSearch.author];
  }
  if (query[nameRouterSearch.complete]) {
    filter.completedStatus = Number(query[nameRouterSearch.complete]);
  }
  if (query[nameRouterSearch.typeStory]) {
    filter.storyTypesId = Number(query[nameRouterSearch.typeStory]);
  }
  if (query[nameRouterSearch.sort]) {
    sort = [query[nameRouterSearch.sort], 'desc'];
  }
  if (query[nameRouterSearch.rank]) {
    sort = [query[nameRouterSearch.rank], 'desc'];
  }
  if (query[nameRouterSearch.rank] && query[nameRouterSearch.time]) {
    filter.inDate = Number(query[nameRouterSearch.time]);
  }
  if (query[nameRouterSearch.chuong]) {
    let numberChapter = [];
    switch (query[nameRouterSearch.chuong]) {
      case '299':
        numberChapter = [0, 299];
        break;
      case '1000':
        numberChapter = [300, 1000];
        break;
      case '2000':
        numberChapter = [1001, 2000];
        break;

      default:
        numberChapter = [2001, -1];
        break;
    }
    filter.inChapter = numberChapter;
  }

  if (query[nameRouterSearch.tag]) {
    filter.storyTagsId = Number(query[nameRouterSearch.tag]);
  }
  //  danh mục cha
  if (query[nameRouterSearch.parentCate]) {
    filter.storyCategoriesId = Number(query[nameRouterSearch.parentCate]);
  }
  // Danh mục con
  if (query[nameRouterSearch.childCate]) {
    filter.storyCategoriesId = Number(query[nameRouterSearch.childCate]);
  }
  // if (query[nameRouterSearch.page]) {
  //     const last = Number(query[nameRouterSearch.page]) * pageSize -1
  //   range = [last - pageSize, last]
  // }

  if (query[nameRouterSearch.page]) {
    const currentPage = Number(query[nameRouterSearch.page])
    const newPageSize = [pageSize * (currentPage - 1), pageSize * currentPage - 1]
    // const last = Number(query[nameRouterSearch.page]) * pageSize -1
    range = newPageSize
  }
  return {
    filter,
    sort,
    range
  };
};

export {
  nameRouterSearch,
  completeArr,
  rankArr,
  numberChapterArr,
  sortArr,
  timeArr,
  convertDataFilterStories,
  categoriesNewsId,
  adsPositionsId,
  adsPositionsCenterId,
  adsPositionsCenterMobileId
};
