/**
 * đối tượng routes cho web và cms
 * @param { là tên không in hoa = tiếng anh viết liền, cách nhau bằng dấu _ và là duy nhất } name
 * @param { là đường dẫn trên trình duyệt, bắt đầu bằng / } path
 * @param { là đường dẫn đến file vật lý trong thư mục pages } component
*/
module.exports.routes = {
  // web
  web: [
    { path: '/', name: "home", component: 'index' },
    { path: '/home', name: "home", component: 'index' },
    { path: '/login', name: "login", component: 'User/LoginMobile' },
    {
      path: '/tim-kiem-tong-hop', name: 'search', component: 'User/Search'
    },
    {
      path: '/tai-app', name: 'downloadApp', component: 'User/DownloadApp'
    },
    {
      path: '/bang-xep-hang', name: 'Order', component: 'User/Order'
    },
    {
      path: '/danh-gia', name: 'Review', component: 'User/Review'
    },
    {
      path: '/trang-ca-nhan/:name([a-zA-Z0-9-_]{1,100})',
      name: 'User',
      component: 'User/User',
    },
    {
      path: '/the-loai/:name([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})',
      name: 'Category',
      component: 'User/Category',
    },
    {
      path: '/tap-van/:name([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})',
      name: 'News',
      component: 'User/News',
    },
    { path: '/doc-truyen/:name([a-zA-Z0-9-]{1,100})/:id([0-9]{1,100})', name: "detailcategory", component: 'User/DetailCategory' },
    {
      path: '/:name([a-zA-Z0-9\-]{1,100})/:storiesId([0-9]{1,100})/:storySectionsNumber([0-9]{1,100})/:storyChaptersNumber([a-zA-Z0-9\-]{1,100})',
      name: 'Chuong',
      component: 'User/Chuong',
    },
  ],
  dashboard: []
};


