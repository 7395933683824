import NextHead from 'next/head';
import { string } from 'prop-types';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const defaultOGSiteName = `${publicRuntimeConfig.APP_NAME}`;
const defaultOGType = `${publicRuntimeConfig.APP_TYPE}`;
const defaultIcon = `/static/web/img/logo_icon.png`
const origin = publicRuntimeConfig.IMAGE_SERVER_NEW + publicRuntimeConfig.IMAGE_PROJECT;

const Head = ({ title, keywords, description, siteName, type, ogImage, dataSite }) => {
  const icon = dataSite?.icon?.file;
  const titleName = title || (dataSite && dataSite.name)
  const defaultImage = ogImage || dataSite?.logo?.length > 0 && dataSite?.logo[0]?.file || ''
  let url;
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }
  return (

    <NextHead>
      <title>{titleName || ''}</title>
      <meta name="keywords" content={keywords} />
      <meta
        name="description"
        content={description}
      />
      {/* Google / Search Engine Tags  */}
      <meta itemProp="name" content={titleName} />
      <meta itemProp="image" content={`${defaultImage?.indexOf('http') === -1 && origin || ''}${defaultImage}`} />

      {/* Facebook Meta Tags  */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={titleName} />
      <meta name="robots" content="index,follow,all" />
      <meta property="og:site_name" content={siteName || defaultOGSiteName} />
      <meta property="og:type" content={type || defaultOGType} />
      <meta
        property="og:description"
        content={description}
      />
      <meta name="twitter:site" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={`${defaultImage?.indexOf('http') === -1 && origin || ''}${defaultImage}`} />
      <meta property="og:image" content={`${defaultImage?.indexOf('http') === -1 && origin || ''}${defaultImage}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <link rel="canonical" href={url} />
      <link rel="icon" type="image/png" sizes="32x32" href={icon ? `${(icon?.indexOf('http') === -1 && origin || '') + icon}` : defaultIcon} />
      <link rel="icon" type="image/png" sizes="96x96" href={icon ? `${(icon?.indexOf('http') === -1 && origin || '') + icon}` : defaultIcon} />
      <link rel="icon" type="image/png" sizes="16x16" href={icon ? `${(icon?.indexOf('http') === -1 && origin || '') + icon}` : defaultIcon} />
    </NextHead>
  )
};

export default Head
